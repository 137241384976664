import { makeApiRequest } from '@/utils/request'

export function postSendCv (param) {
  // console.log('API', param)
  return makeApiRequest({
    url: '/dev-api/feedback/sendCv',
    method: 'POST',
    data: param
  })
}
export function postSendMessage (param) {
  // console.log('API', param)
  return makeApiRequest({
    url: '/dev-api/feedback/sendMessage',
    method: 'POST',
    data: param
  })
}
